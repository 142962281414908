import { render } from "./Failed.vue?vue&type=template&id=615a1a40"
import script from "./Failed.vue?vue&type=script&lang=ts"
export * from "./Failed.vue?vue&type=script&lang=ts"

import "./failed.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "615a1a40"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('615a1a40', script)) {
    api.reload('615a1a40', script)
  }
  
  module.hot.accept("./Failed.vue?vue&type=template&id=615a1a40", () => {
    api.rerender('615a1a40', render)
  })

}

script.__file = "src/views/Payment/Failed.vue"

export default script